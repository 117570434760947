export const homeAndFormTexts = {
    formSection: {
        name: "Nombre *",
        phone: "Teléfono *",
        email: "Correo *",
        message: "Si tienes dudas escríbenos aquí tu consulta ...",
        rgpdText: "Acepto el tratamiento de datos y políticas de privacidad de Thinkia (Search Engine Business S.L.).*",
        informationButton: "Quiero más información"
    }
};
export const sectionTwoTexts = {
    mainSection: {
        title: {
            firstPart: "Aprende de la mano de destacados profesionales del sector, en activo y con contrastada experiencia docente.",
            secondPart: "Adquiere nuevos conocimientos y las habilidades que demandan las empresas."
        },
        stats: [
            {
                number: "12",
                label: "SEMANAS"
            },
            {
                number: "120",
                label: "HORAS"
            },
            {
                number: "10",
                label: "DOCENTES EXPERTOS"
            }
        ]
    },
    learnSection: {
        title: "¿Qué vas a conseguir?",
        benefits: [
            {
                text: "Conocimiento de las funciones y herramientas DevOps."
            },
            {
                text: "Definición y creación de la estrategia de arquitectura de sistemas de la empresa.",
                
            },
            {
                text: "Creación y administración de servicios en la nube para diversas empresas y organizaciones."
            },
            {
                text: "Implementación de continuous delivery y orquestación de servicios.",
                
            },
            {
                text: "Especialización en un perfil con creciente demanda."
            }
        ]
    }
};

export const accordionTexts = {
    title: "Contenido del Máster",
    modules: [
        {
            number: 'Módulo 01',
            title: 'Herramientas para DevOps',
            content: [
                {
                    list: [
                        'Repaso a Vagrant, Ansible y Docker: Revisión de las herramientas esenciales del máster. Aprendizaje de conceptos básicos, instalación en el entorno de trabajo y ejecución.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 02',
            title: 'Agile Dev. y Herramientas',
            content: [
                {
                    list: [
                        '<strong>Cultura DevOps:</strong> Entender la necesidad de un cambio cultural hacia la colaboración y la integración entre desarrollo y sistemas. Exploración del rol de DevOps y su adopción en las empresas.',
                        '<strong>Ciclo completo del software:</strong> Uso de Git para control de versiones, introducción a la metodología Agile, y herramientas y prácticas recomendadas para Git/Gitflow. Mejora de la eficiencia del equipo de desarrollo y estrategias de trabajo con Git/Gitflow.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 03',
            title: 'Bases de la Infraestructura',
            content: [
                {

                    list: [
                        'Aprovisionamiento de recursos: Implementación de infraestructura en diversas plataformas, tanto en instalaciones locales como en la nube. Gestión de recursos como máquinas virtuales, balanceadores de carga y contenedores.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 04',
            title: 'Infraestructura como Código',
            content: [
                {
                    list: [
                        '<strong>Automatización y Arquitectura como Código:</strong> Aplicación de procesos estandarizados para la automatización de tareas y mejora de la fiabilidad del software. Uso de herramientas para definir y replicar ambientes de ejecución mediante scripts.',
                        '<strong>Gestión de Infraestructura:</strong> Uso de herramientas como Puppet, Chef, Terraform, Ansible y Packer para crear, modificar, eliminar y provisionar infraestructura. Enfoque en Terraform para la automatización de tareas.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 05',
            title: 'Sistemas de Gestión de la Infraestructura',
            content: [
                {
                    list: [
                        '<strong>Contenedores:</strong> Creación y gestión de contenedores con Docker, composición de aplicaciones para soportar microservicios y adopción del flujo den trabajo de contenedores.',
                        '<strong>Kubernetes:</strong> Conceptos y aplicaciones básicas y avanzadas de Kubernetes. Implementación y administración de clústeres de contenedores',
                        '<strong>Orquestación:</strong> Uso de Jenkins para la automatización y orquestación, incluyendo la configuración de Pipelines, cobertura de código y herramientas de calidad.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 06',
            title: 'Continuous Integration & Continuous Delivery',
            content: [
                {
                    list: [
                        '<strong>Alta disponibilidad:</strong> Configuración de infraestructuras para garantizar continuidad operativa. Diferenciación entre alta disponibilidad y alta concurrencia, y análisis de cuellos de botella.',
                        '<strong>Continuous Testing:</strong> Integración de pruebas automatizadas en el ciclo de desarrollo. Diseño y ejecución de pruebas de comportamiento, análisis de resultados con JMeter y monitoreo con Cloud Watch.',
                        '<strong>SecDevOps y Log Analytics:</strong> Monitoreo continuo para mejorar la colaboración entre Dev y Ops, y optimización de la experiencia del usuario. Uso de herramientas como Splunk, ELK (Elasticsearch / Logstash / Kibana) y Grafana para la gestión y análisis de logs.'
                    ]

                }
            ]
        },
        {
            number: 'Módulo 07',
            title: 'Infraestructuras cloud',
            content: [
                {
                    list: [
                        '<strong>Infraestructuras Cloud:</strong> Conceptos clave de las infraestructuras cloud. Servicios de almacenamiento, base de datos, funciones serverless. Despliegue de clúster de Kubernetes con Terraform.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 08',
            title: 'Proyecto Final',
            content: [
                {
                    list: [
                        '<strong>Automatización de una aplicación web básica:</strong> Creación de todo el ciclo de vida de un entorno automatizado para una aplicación web, independientemente del lenguaje o framework utilizado. Confirmación del aprendizaje y aplicación de los conceptos del máster.'
                    ]
                }
            ]
        }
    ]
};
export const targetAudienceTexts = {
    mainTitle: "¿A quién va dirigido?",
    prerequisites: {
        title: "Pre-requisitos:",
        description: "Está dirigido a perfiles de nivel medio que deseen avanzar en su carrera profesional en el ámbito tecnológico. Se requieren conocimientos básicos debmetodologías ágiles, con el objetivo de mejorar el rendimiento."
    },
    profiles: {
        title: "Orientado a perfiles como",
        list: [
            "<strong>Developer, Senior Developer, Sysadmin, Tech Project Manager, Tech Leader, Development Manager, CTO.</strong>",
            "Empresas que reconocen la importancia de capacitar a su equipo IT para implementar sistemas e infraestructuras que optimicen el trabajo y el despliegue.",
            "<strong>Sysadmins</strong> interesados en aprender sobre infraestructuras en la nube y orquestación de servicios.",
            "<strong>Desarrolladores</strong> que desean adquirir conocimientos en automatización y virtualización.",
            "<strong>Profesionales IT</strong> en empresas que buscan implementar continuous delivery y aprovisionamiento inteligente.",
            "<strong>Perfiles DevOps</strong> que necesitan actualizarse."
        ]
    },
    features: [
        {
            iconAlt: "Icono casa",
            text: "Desde Casa"
        },
        {
            iconAlt: "Icono Másterclass",
            text: "Másterclasses en directo"
        },
        {
            iconAlt: "Icono expertos",
            text: "Profesionales expertos en activo"
        }
    ]
};
export const upcomingSectionTexts = {
    mainTitle: "Próximas Convocatorias",
    edition: {
        items: [
            {
                text: "I Edición"
            },
            {
                text: "4 Abril 2025"
            },
            {
                text: "Masterclass en directo\nViernes y Sábados"
            },
            {
                text: "Vie. 15:00 a 20:00h\nSáb. 9:00 a 14:00h"
            }
        ]
    },
    pricing: {
        label: "Precio",
        amount: "3.900€",
        buttonText: "Quiero más información"
    },
    details: {
        title: "Detalles del Máster",
        list: [
            "12 semanas - 120 horas",
            "10 docentes en activo",
            "Certificación al finalizar",
            "Masterclass en directo"
        ]
    }
};
export const faqSectionTexts = {
    title: "Preguntas Frecuentes",
    questions: [
        {
            question: "¿La formación es en directo?",
            answer: "Sí, hemos ideado nuestro Máster en Product Management como una formación completamente online pero sin perder la esencia de poder vivirla compartiendo la experiencia en directo con tus compañeros y profesores. Así pues, cada sesión es en directo para que puedas preguntar tus dudas a tus profesores y hablar con tus compañeros."
        },
        {
            question: "¿Cómo puedo acceder al Máster?",
            answer: "Es tan fácil como completar la solicitud con tus datos y nos pondremos rápidamente en contacto contigo. Agendaremos una entrevista para conocer tus intereses y ayudarte a alcanzar tu objetivo."
        },
        {
            question: "¿Qué requisitos hacen falta?",
            answer: "Partirás con una base si tienes conocimiento sobre producto, desarrollo, data, marketing o negocio, pero no son requerimientos obligatorios."
        },
        {
            question: "¿Qué perfil debo tener para poder acceder?",
            answer: "No existe un perfil ideal ni un background concreto para llegar a ser un buen/a PM, algunos/as llegan desde el mundo de la programación, marketing o negocio por lo que te será más sencillo si provienes de alguna de estas ramas. Aún así, no es necesario tener nociones previas en estas áreas. Nuestro equipo de Admisiones realizará una entrevista contigo para conocerte mejor y poder asesorarte en tu carrera profesional."
        },
        {
            question: "¿Por qué elegir este Máster y no otro?",
            answer: "En Thinkia Training hemos creado un máster 100% online con Masterclass en directo cada viernes y sábado en el que adquirás de la mano de profesionales en activo que tienen experiencia con este rol, y estarás en contacto con compañeros en un punto profesional similar al tuyo, y con las mismas inquietudes. Estamos totalmente comprometidos con tu formación, por eso hemos diseñado un programa adaptado a las necesidades actuales de las empresas con profesores que son profesionales de alto nivel en activo."
        },
        {
            question: "¿Lo puedo hacer mientras estoy trabajando?",
            answer: "El horario está adaptado al horario laboral para que puedas compaginar tu trabajo con tu formación. Además, al tratarse de una formación online puedes conectarte desde cualquier lugar a la hora de la Masterclass y pondremos a tu disposición la documentación que aporte cada profesor en nuestra plataforma formativa. Y si algún día no puedes conectarte las sesiones se grabarán para verlas luego. o poder repasar la clase con el experto."
        }
    ]
};

const textContextData = {
    homeAndFormTexts,
    sectionTwoTexts,
    accordionTexts,
    targetAudienceTexts,
    upcomingSectionTexts,
    faqSectionTexts
};

export default textContextData;