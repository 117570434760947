import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/UpcomingSection.scss';
import { upcomingSectionTexts } from '../data/textContext';

const UpcomingSection = () => {
  const ArrowIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.33012 13.67L4.26946 12.6093L10.6334 6.24534L4.79979 6.24534L4.79979 4.7392H13.2002V13.1396L11.6941 13.1396L11.6941 7.306L5.33012 13.67Z" fill="black"/>
    </svg>
  );
  const scrollToHome = () => {
    const homeSection = document.getElementById('home');
    if (homeSection) {
      homeSection.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  };


  return (
    <section className="upcoming-section-main" id="precio">
      <div className="container upcoming-section p-5 mt-4">
        <h2 className="upcoming-section-title text-center">
          {upcomingSectionTexts.mainTitle}
        </h2>
        
        <div className="row justify-content-center align-items-center my-4">
          <div className="col-lg-12 d-flex flex-md-row flex-column justify-content-between upcoming-section-info p-4 pb-md-3">
            {upcomingSectionTexts.edition.items.map((item, index) => (
              <div key={index} className="col-md-3 text-white text-center upcoming-text-information-inner">
                <p className='upcoming-text-information-inner'>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
        
        <div className="row text-center justify-content-center">
          <div className="col-md-4 mt-5 text-center d-flex flex-column align-items-center order-2 order-md-1">
            <p className="price-upcoming-label-section">
              {upcomingSectionTexts.pricing.label}
            </p>
            <p className="price-upcoming-section">
              {upcomingSectionTexts.pricing.amount}
            </p>
            <button className="btn-info-section-upcoming mt-4" onClick={scrollToHome}>
              {upcomingSectionTexts.pricing.buttonText} <ArrowIcon />
            </button>
          </div>
          
          <div className="col-md-4 mt-4 order-1">
            <h3 className="upcoming-section-title-info">
              {upcomingSectionTexts.details.title}
            </h3>
            <ul className="details-upcoming-list-section my-2 mt-md-5">
              {upcomingSectionTexts.details.list.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="triangle-divider-upcoming-section"></div>
    </section>
  );
};

export default UpcomingSection;