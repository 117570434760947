import React, { useLayoutEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/common_ccs/HeaderLegal.css';
import Footer from './FooterProductManagement';
import LogoThinkia from '../../assets/logos/LogoThinkiaTraining.png';
import { Link } from 'react-router-dom';

const AvisoLegal = () => {

    useLayoutEffect(() => {
        document.documentElement.style.scrollBehavior = 'auto';
        window.scrollTo(0, 0);
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
        
        return () => {
            document.documentElement.style.scrollBehavior = '';
        };
    }, []);


    return (
        <div className="main-container">
            <header className="d-flex justify-content-between align-items-center p-4 text-white header-container-aviso-legal">
                <div className="logo px-md-5">
                    <Link to="/"><img src={LogoThinkia} alt="Logotipo" className="logo-landing-product-management img-fluid" /></Link>
                </div>
                <div className="button-aviso-legal px-md-5">
                    <button className="btn btn-primary button-aviso-legal border-5 rounded-pill">Contacto</button>
                </div>
            </header>
            <div className="avisos-legales-body">
                <div className="container">
                    <div className="row mt-4 mb-4">
                        <div className="col-md-12 d-flex flex-row justify-content-center">
                            <h1 className="aviso-legal-main-title">Aviso Legal</h1>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            <p className="aviso-legal-description mb-4">Este sitio web es propiedad de SEARCH ENGINE BUSINESS SL (en adelante THINKIA), con domicilio social en Calle Velázquez, 28 3º 28001 Madrid, y CIF B01764703. Está inscrita en el Registro Mercantil de Madrid en el Tomo 40901, Folio 80, Sección M-725560. Teléfono +34 919 04 92 29, correo electrónico info@searchengine.group.</p>
                            <p className="aviso-legal-description mb-4">THINKIA se reserva el derecho de modificar, en cualquier momento y sin previo aviso, la configuración y los contenidos del sitio web. La información en este sitio se proporciona sin garantía de ningún tipo, ya sea explícita o implícita, y puede ser modificada o actualizada sin previo aviso. El usuario se compromete a no realizar ninguna conducta que pueda perjudicar los intereses y derechos de THINKIA o de terceros. Toda la información recibida por THINKIA a través de este sitio web no será considerada confidencial, salvo la incluida en la sección de confidencialidad de datos. Cualquier persona física o jurídica que desee establecer un hipervínculo o enlace deberá contar con la autorización previa de THINKIA.</p>
                            <p className="aviso-legal-description mb-4">THINKIA no asume ninguna responsabilidad derivada de los contenidos que terceros hayan incluido en los hipervínculos referidos en esta página. El establecimiento del hipervínculo no implica en ningún caso la existencia de relaciones entre THINKIA y el titular de la página web en la que se establezca, ni la aceptación y aprobación por parte de THINKIA de sus contenidos o servicios ofrecidos en la misma.</p>
                            <p className="aviso-legal-description mb-4">Queda expresamente prohibida cualquier alteración, cambio, modificación o adaptación de esta página web o de cualquier información contenida en ella. THINKIA no es responsable de ninguna pérdida o daño derivado del acceso a su sitio web o del uso de la información o aplicaciones contenidas en él. Asimismo, no se responsabiliza ni garantiza la disponibilidad y continuidad en el acceso a esta página web ni que esté libre de errores.</p>
                            <p className="aviso-legal-description">El usuario será responsable de los daños y perjuicios de cualquier naturaleza que THINKIA pueda sufrir como consecuencia del incumplimiento de cualquiera de las obligaciones a las que esté sujeto en virtud de estas condiciones. El usuario es consciente y acepta voluntariamente que el uso de cualquier contenido de este sitio web se realiza, en todo caso, bajo su única y exclusiva responsabilidad. THINKIA y el usuario, con renuncia expresa a cualquier otro fuero, se someten a la Ley Española y a los juzgados y tribunales del domicilio de THINKIA en Madrid, para cualquier controversia que pudiera derivarse del acceso a este sitio web.</p>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <Footer />
            </footer>
        </div>
    );
};

export default AvisoLegal;
