import React from "react";
import "../css/SectionTargetAudience.scss";
import imageTargetAudience from "src/assets/img/DevOps3.jpeg";
import icono1 from "src/assets/img/Group.png";
import icono2 from "src/assets/img/Group15974.png";
import icono3 from "src/assets/img/Vector.png";
import { targetAudienceTexts } from "../data/textContext";

const SectionTargetAudience = () => {
  const icons = [icono1, icono2, icono3];

  return (
      <section >
          <div className="section-target-audience mt-5" id="paraquien">
              <div className="container">
                  <div className="row row-section-target-audience">
                      <div className="col-md-7 text-section-target-audience">
                          <h2 className="title-section-target-audience text-center text-md-start mb-3">
                              {targetAudienceTexts.mainTitle}
                          </h2>
                          <h4 className="subtitle-section-target-audience text-primary-section-target-audience">
                              {targetAudienceTexts.prerequisites.title}
                          </h4>
                          <div className="col-md-9 col-target-audience9">
                              <p className="description-section-target-audience text-white">
                                  {targetAudienceTexts.prerequisites.description}
                              </p>
                              <ul className="description-section-target-audience">
                                  {targetAudienceTexts.prerequisites.list?.map((prerequisites, index) => (
                                      <li key={index} className="item-section-target-audience">
                                          <p className="highlight-section-target-audience mb-0">
                                              <div dangerouslySetInnerHTML={{ __html: prerequisites }} />
                                          </p>
                                      </li>
                                  ))}
                              </ul>
                          </div>
                          <h4 className="subtitle-section-target-audience text-primary-section-target-audience mb-3">
                              {targetAudienceTexts.profiles.title}
                          </h4>
                          <div className="col-md-9 col-target-audience9">
                              <ul className="list-section-target-audience">
                                  {targetAudienceTexts.profiles.list?.map((profile, index) => (
                                      <li key={index} className="item-section-target-audience">
                                          <p className="highlight-section-target-audience">
                                              <div dangerouslySetInnerHTML={{ __html: profile }} />
                                          </p>
                                      </li>
                                  ))}
                              </ul>
                          </div>
                      </div>

                      <div className="col-md-5 image-section-target-audience text-center">
                          <img
                              src={imageTargetAudience}
                              alt="Profesionales trabajando"
                              className="img-fluid img-section-target-audience mb-4"
                          />

                          <div className="d-flex flex-column align-items-start icons-section-target-audience">
                              {targetAudienceTexts.features?.map((feature, index) => (
                                  <div key={index} className="icon-item-section-target-audience mb-3">
                                      <img 
                                          src={icons[index]} 
                                          alt={feature.iconAlt} 
                                          className="icon-target-audience"
                                      />
                                      <span className="icon-text-targent-audience">
                                          {feature.text}
                                      </span>
                                  </div>
                              ))}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  );
};

export default SectionTargetAudience;
