import React, { useLayoutEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/common_ccs/HeaderLegal.css';
import Footer from './FooterProductManagement';
import LogoThinkia from '../../assets/logos/LogoThinkiaTraining.png';
import { Link } from 'react-router-dom'; // Importa Link

const Cookies = () => {

    useLayoutEffect(() => {
        document.documentElement.style.scrollBehavior = 'auto';
        window.scrollTo(0, 0);
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
        
        return () => {
            document.documentElement.style.scrollBehavior = '';
        };
    }, []);


    return (
        <div className="main-container">
            <header className="d-flex justify-content-between align-items-center p-4 text-white header-container-aviso-legal">
            <div className="logo px-md-5">
                    <Link to="/"><img src={LogoThinkia} alt="Logotipo" className="logo-landing-product-management img-fluid" /></Link>
                </div>
                <div className="button-aviso-legal px-md-5">
                    <button className="btn btn-primary button-aviso-legal border-5 rounded-pill">Contacto</button>
                </div>
            </header>
            <div className="avisos-legales-body px-2">
                <div className="container">
                    <div className="row mt-4 mb-4">
                        <div className="col-md-12 d-flex flex-row justify-content-center">
                            <h1 className="aviso-legal-main-title">Política de Cookies</h1>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            <p className="aviso-legal-description-privacidad mb-4">Política de Cookies</p>
                            <p className="aviso-legal-description-privacidad mb-4">SEARCH ENGINE BUSINESS SL, en adelante THINKIA, informa mediante la presente Política de Cookies sobre el uso de dispositivos de almacenamiento y recuperación de datos en los equipos terminales de los Usuarios.</p>
                            <h3 className="privacidad-main-title my-3">¿Qué son las cookies?</h3>
                            <p className="aviso-legal-description-privacidad mb-4">Las cookies son archivos que se descargan en el equipo del Usuario, ya sea un ordenador, smartphone o tablet, al acceder a determinadas páginas web y aplicaciones, permitiendo almacenar las preferencias de navegación del Usuario. Esto facilita una interacción más rápida y eficiente entre el Usuario y el Sitio Web</p>
                            <p className="aviso-legal-description-privacidad mb-4">La información que recogen las cookies es anónima y no incluye datos personales del Usuario. En cualquier caso, el Usuario puede configurar su navegador para modificar o bloquear la instalación de cookies, sin que ello impida el acceso a los contenidos de la página.</p>
                            <h3 className="privacidad-main-title my-3">¿Para qué utiliza THINKIA las cookies?</h3>
                            <p className="aviso-legal-description-privacidad mb-4">Este sitio web utiliza cookies u otros dispositivos de almacenamiento y recuperación de información para hacer seguimiento de las interacciones de los Usuarios con los servicios ofrecidos.</p>
                            <p className="aviso-legal-description-privacidad mb-4">Este sitio web utiliza cookies u otros dispositivos de almacenamiento y recuperación de información para hacer seguimiento de las interacciones de los Usuarios con los servicios ofrecidos.</p>
                            <p className="aviso-legal-description-privacidad mb-4">En resumen, THINKIA usa cookies para optimizar la navegación, adaptando la información y servicios ofrecidos a los intereses y preferencias del Usuario.</p>
                            <h3 className="privacidad-main-title my-3">Tipos de cookies utilizadas en el Sitio Web</h3>
                            <p className="aviso-legal-description-privacidad mb-4">El sitio web emplea los siguientes tipos de cookies, aunque una cookie puede pertenecer a más de una categoría:</p>
                            <p className="aviso-legal-description-privacidad mb-2"><strong>Cookies propias:</strong> Permiten el funcionamiento correcto del Sitio Web y son esenciales para que el Usuario pueda utilizar todas sus opciones.</p>
                            <p className="aviso-legal-description-privacidad mb-2"><strong>Cookies de terceros:</strong> Enviadas desde un equipo o dominio no gestionado por THINKIA, sino por otra entidad que trata los datos obtenidos.</p>
                            <p className="aviso-legal-description-privacidad mb-2"><strong>Cookies de personalización:</strong> Permiten al Usuario acceder al servicio con características generales predefinidas según ciertos criterios, como el idioma o el tipo de navegador.
                            </p>
                            <p className="aviso-legal-description-privacidad mb-2"><strong>Cookies de análisis:</strong> Permiten monitorizar y analizar el comportamiento de los Usuarios para medir la actividad y elaborar perfiles de navegación, con el fin de mejorar el servicio.</p>
                            <p className="aviso-legal-description-privacidad mb-2"><strong>Cookies publicitarias:</strong> Permiten gestionar de la manera más eficiente posible los espacios publicitarios en función de criterios como el contenido editado o la frecuencia de los anuncios.</p>
                            <p className="aviso-legal-description-privacidad mb-2"><strong>Cookies de publicidad comportamental:</strong> Almacenan información sobre el comportamiento de los Usuarios obtenida mediante la observación continua de sus hábitos de navegación, desarrollando un perfil específico para mostrar publicidad.</p>

                            <h3 className="privacidad-main-title my-3">¿Cómo desactivar las cookies?</h3>
                            <p className="aviso-legal-description-privacidad mb-4">Todos los navegadores permiten cambios para desactivar la configuración de cookies. Estos ajustes se encuentran en las opciones o preferencias del navegador. A continuación, se proporcionan enlaces para desactivar las cookies en los principales navegadores:</p>
                            <ul className="aviso-legal-description-list list-unstyled mb-4">
                                <li className="browsers-names-link-cookies">Internet Explorer:<a className="browsers-link-cookies" href="https://goo.gl/iU2wh2" target="_blank" rel="noreferrer"> https://goo.gl/iU2wh2</a></li>
                                <li className="browsers-names-link-cookies">Mozilla Firefox:<a className="browsers-link-cookies" href="http://goo.gl/QXWYmv" target="_blank" rel="noreferrer"> http://goo.gl/QXWYmv</a></li>
                                <li className="browsers-names-link-cookies">Google Chrome:<a className="browsers-link-cookies" href="http://goo.gl/fQnkSB" target="_blank" rel="noreferrer"> http://goo.gl/fQnkSB</a></li>
                                <li className="browsers-names-link-cookies">Safari:<a className="browsers-link-cookies" href="https://goo.gl/dQywEo" target="_blank" rel="noreferrer"> https://goo.gl/dQywEo</a></li>

                            </ul>
                            <p className="aviso-legal-description-privacidad mb-4">Nota: La desactivación de cookies puede afectar a ciertas funcionalidades del Sitio Web.:</p>
                            <p className="aviso-legal-description-privacidad mb-4">Si no desea ser rastreado por cookies, Google ha desarrollado un complemento de inhabilitación que puede instalar en su navegador en el siguiente enlace:<a className=" text-decoration-none text-black" href="http://goo.gl/up4ND." target="_blank" rel="noreferrer"> http://goo.gl/up4ND.</a></p>

                            <h3 className="privacidad-main-title my-3">¿Por cuánto tiempo conservaremos sus datos?</h3>
                            <p className="aviso-legal-description-privacidad">Los datos proporcionados se conservarán mientras el interesado no solicite su supresión. Todas nuestras comunicaciones comerciales incluyen la opción de darse de baja. Si ejerce la opción de “baja”, sus datos serán eliminados de nuestro archivo general de comunicaciones.</p>
                            <h3 className="privacidad-main-title my-3">¿Cuál es la legitimación para el tratamiento de sus datos?</h3>
                            <p className="aviso-legal-description-privacidad">La base legal para el tratamiento de sus datos puede incluir:</p>
                            <ul className="aviso-legal-description-list mb-4">
                                <li className="">El registro en nuestros sistemas para solicitar información sobre un producto o servicio o para contratarlo o usarlo;</li>
                                <li>La compra o uso de cualquiera de nuestros productos y servicios;</li>
                                <li>La suscripción a boletines informativos, alertas u otros servicios;</li>
                                <li>El contacto a través de nuestros canales;</li>
                                <li>La participación en un concurso, sorteo o encuesta;</li>
                                <li>La navegación por nuestro sitio web o por otros sitios de THINKIA;</li>
                                <li>Su autorización a terceros, como nuestros colaboradores o proveedores, para compartir su información con nosotros;</li>
                                <li>La disponibilidad pública de la información y su uso permitido legalmente;</li>
                                <li>Ser cliente de un negocio o entidad que THINKIA haya adquirido.</li>
                            </ul>
                            <h3 className="privacidad-main-title my-3">Cookies en dispositivos móviles</h3>
                            <p className="aviso-legal-description-privacidad">THINKIA también utiliza cookies en dispositivos móviles. </p>
                            <p className="aviso-legal-description-privacidad">Los navegadores de dispositivos móviles permiten modificar las opciones de privacidad para desactivar o eliminar cookies.</p>
                            <p className="aviso-legal-description-privacidad">A continuación, se indican enlaces de ayuda para cambiar la configuración de privacidad en algunos navegadores móviles:</p>
                            <ul className="aviso-legal-description-list mb-4">
                                <li className="">iOS:<a className=" text-decoration-none" href="http://goo.gl/61xevS" target="_blank" rel="noreferrer"> (http://goo.gl/61xevS)</a></li>
                                <li>Windows Phone:<a className=" text-decoration-none" href="https://goo.gl/tKyb0y" target="_blank" rel="noreferrer"> (https://goo.gl/tKyb0y)</a></li>
                                <li>Chrome Mobile:<a className=" text-decoration-none" href="http://goo.gl/XJp7N" target="_blank" rel="noreferrer"> (http://goo.gl/XJp7N)</a></li>
                                <li>Opera Mobile:<a className=" text-decoration-none" href="http://goo.gl/Nzr8s7" target="_blank" rel="noreferrer"> (http://goo.gl/Nzr8s7)</a></li>
                            </ul>
                            <div className="hidde-container d-none d-md-block"></div>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <Footer />
            </footer>
        </div>
    );
};

export default Cookies;
