import React from 'react';
import '../../assets/css/thinkiaTraining.css'
import userandcomputer from '../../assets/img/thinkiaTraining/userandcomputer.png';
import imageGroup from '../../assets/img/thinkiaTraining/imagen_grupo.png';
import thinkandhand from '../../assets/img/thinkiaTraining/thinkandhand.png';
import Logo from '../../assets/img/logo/Group.png';

function ThinkiaTraining() {
  return (
    <div className="container-fluid p-4 mx-auto px-md-5" style={{ backgroundColor: '#07081C', color: 'white' }} id="thinkia-training">
      <div className="row text-center">
        <div className="col">
        <div className="d-flex flex-row justify-content-center align-items-center mb-2 mt-4">
            <img src={Logo} alt="Logo thinkia" className="mb-3 " style={{ marginRight: '10px', maxHeight: '50px', objectFit: 'contain' }} />
            <h1 className="thinkia-training-logo-text">Training</h1>
        </div>
          <h2 className="thinkia-training-title">Formamos a los profesionales de IT y especialistas en IA</h2>
        </div>
      </div>
      <div className="row my-5 mx-2 mx-lg-5 align-items-center">
        <div className="col-md-6 order-md-1 text-center thinkia-training-img-container">
          <img src={userandcomputer} alt="User and computer" className="img-fluid thinkia-training-image" />
        </div>
        <div className="col-md-6 order-md-2 d-flex align-items-center px-0 px-lg-2">
          <div className="thinkia-training-text-container">
            <h3 className="thinkia-training-description-title-inner mt-4">Formación especialista para profesionales IT en tecnología y en metodologías de trabajo para ser más efectivos.</h3>
            <p className="thinkia-training-description-inner">
              Formación en metodologías de trabajo para ser más efectivos:
            </p>
              <ul className="thinkia-training-bullets-description">
                <li className="thinkia-training-description-inner thinkia-training-bullets-description" >Máster DevOps</li>
                <li className="thinkia-training-description-inner thinkia-training-bullets-description" >Máster en Ciberseguridad</li>
                <li className="thinkia-training-description-inner thinkia-training-bullets-description" >Máster Product Management</li>
                <li className="thinkia-training-description-inner thinkia-training-bullets-description" >Máster Agile Management</li>
              </ul>
              <p className="thinkia-training-description-inner mt-lg-3">Entre 100 y 120h lectivas. Horario ejecutivo (viernes tarde y sábado mañana). Bonificable por FUNDAE.</p>
          </div>
        </div>
      </div>
      <hr />

      <div className="row my-5 mx-2 mx-lg-5 align-items-center">
        <div className="col-md-6 order-md-1 text-center thinkia-training-img-container">
          <img src={imageGroup} alt="group imagen" className="img-fluid thinkia-training-image" />
        </div >
        <div className="col-md-6 order-md-2 d-flex align-items-center px-0 px-lg-2">
          <div className="thinkia-training-text-container">
            <h3 className="thinkia-training-description-title-inner mt-4">Formación tech a medida</h3>
            <p className="thinkia-training-description-inner mt-3">Lenguajes de programación, frameworks, arquitectura y buenas prácticas.</p>
            <p className="thinkia-training-description-inner mt-1">Creamos formaciones a medida adaptadas a las necesidades de las empresas.</p>
          </div>
        </div>
      </div>
      <hr />
      <div className="row my-5 mx-2 mx-lg-5 align-items-center">
        <div className="col-md-6 order-md-1 text-center thinkia-training-img-container">
          <img src={thinkandhand} alt="thinkandhand" className="img-fluid thinkia-training-image" />
        </div>
        <div className="col-md-6 order-md-2 d-flex align-items-center px-0 px-lg-2">
          <div className="thinkia-training-text-container">
            <h3 className="thinkia-training-description-title-inner mt-4">Escuela de IA (Próximamente)</h3>
            <p className="thinkia-training-description-inner mt-3">Para aprender y ser una persona que construye IA.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThinkiaTraining;
