import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/SectionTargetAudience.css';
import imageTargetAudience from '../assets/img/AgileManagement2.jpeg';
import icono1 from '../assets/logos/Group.png';
import icono2 from '../assets/logos/Group15974.png';
import icono3 from '../assets/logos/Vector.png';
import { targetAudienceTexts } from '../data/textContext';

const SectionTargetAudience = () => {
    const icons = [icono1, icono2, icono3];

    return (
        <section>
            <div className="section-target-audience mt-5" id="paraquien">
                <div className="container">
                    <div className="row row-section-target-audience">
                        <div className="col-md-7 text-section-target-audience">
                            <h2 className="title-section-target-audience text-center text-md-start mb-3">
                                {targetAudienceTexts.mainTitle}
                            </h2>
                            <h4 className="subtitle-section-target-audience text-primary-section-target-audience">
                                {targetAudienceTexts.prerequisites.title}
                            </h4>
                            <div className="col-md-9 col-target-audience9">
                                <ul className="">
                                <li className="description-section-target-audience text-white">
                                {targetAudienceTexts.prerequisites.description}
                                    </li>
                                </ul>
                            </div>
                            <h4 className="subtitle-section-target-audience text-primary-section-target-audience mb-3">
                                {targetAudienceTexts.profiles.title}
                            </h4>
                            <div className="col-md-9 col-target-audience9">
                                <ul className="list-section-target-audience">
                                    
                                    {targetAudienceTexts.profiles.list.map((profile, index) => (
                                        <li key={index} className="item-section-target-audience">
                                            <strong className="highlight-section-target-audience">
                                                {profile}
                                            </strong>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-5 image-section-target-audience text-center">
                            <img
                                src={imageTargetAudience}
                                alt="Profesionales trabajando"
                                className="img-fluid img-section-target-audience mb-4"
                            />

                            <div className="d-flex flex-column align-items-start icons-section-target-audience">
                                {targetAudienceTexts.features.map((feature, index) => (
                                    <div key={index} className="icon-item-section-target-audience mb-3">
                                        <img
                                            src={icons[index]}
                                            alt={feature.iconAlt}
                                            className="icon-target-audience"
                                        />
                                        <span className="icon-text-targent-audience">
                                            {feature.text}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionTargetAudience;
