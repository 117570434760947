import React, { useState } from 'react';
import '../../assets/css/common_ccs/HamburguesMenu.css';

const HamburgerMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      {/* Botón Hamburguesa */}
      <div className="hamburger-menu" onClick={toggleMenu}>
        <svg className={`burger-6 ham hamRotate ham1 ${menuOpen ? 'is-closed' : ''}`} viewBox="0 0 100 100">
          <path
            className="line top"
            d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"
          />
          <path className="line middle" d="m 30,50 h 40" />
          <path
            className="line bottom"
            d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"
          />
        </svg>
      </div>

      {/* Menú Desplegable en Móvil */}
      {menuOpen && (
        <div className="mobile-menu d-md-none">
          <ul className="menu-list">
            <li><a href="#objetivo">Objetivos</a></li>
            <li><a href="#contenido">Contenido</a></li>
            <li><a href="#paraquien">Para Quién</a></li>
            <li><a href="#precio">Precio</a></li>
            <li><a href="#faq">Preguntas Frecuentes</a></li>
          </ul>
        </div>
      )}
    </>
  );
};

export default HamburgerMenu;

