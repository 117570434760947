import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/FaqSection.css';
import { faqSectionTexts } from '../data/textContext';

const FaqSection = () => {
  const [activeQuestions, setActiveQuestions] = useState([]);

  const toggleQuestion = (index) => {
    setActiveQuestions(currentActiveQuestions => {
      // Checa si el índice ya está en el array
      const indexPosition = currentActiveQuestions.indexOf(index);
      if (indexPosition !== -1) {
        // Si ya está, lo elimina (cierra la pregunta)
        return currentActiveQuestions.filter((_, i) => i !== indexPosition);
      } else {
        // Si no está, lo añade (abre la pregunta)
        return [...currentActiveQuestions, index];
      }
    });
  };

  const isQuestionActive = (index) => {
    return activeQuestions.includes(index);
  };

  const UpIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
      <path d="M16.2504 25.5012L13.7504 25.5012L13.7504 10.5012L6.87539 17.3762L5.10039 15.6012L15.0004 5.70122L24.9004 15.6012L23.1254 17.3762L16.2504 10.5012L16.2504 25.5012Z" fill="#00AFFF" />
    </svg>
  );

  const DownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
      <path d="M13.7496 5.49878L16.2496 5.49878L16.2496 20.4988L23.1246 13.6238L24.8996 15.3988L14.9996 25.2988L5.09961 15.3988L6.87461 13.6238L13.7496 20.4988L13.7496 5.49878Z" fill="#00AFFF" />
    </svg>
  );

  const AccentSvgDesktop = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="d-none d-md-block" width="105" height="121" viewBox="0 0 105 121" fill="none">
      <path d="M0.166016 120.548L104.419 0H61.3806L0.166016 70.1625V120.548Z" fill="#00AFFF" />
    </svg>
  );

  const AccentSvgMobile = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="d-block d-md-none" width="64" height="73" viewBox="0 0 64 73" fill="none">
      <path d="M0 73L63.1319 0H37.0695L0 42.488V73Z" fill="#00AFFF" />
    </svg>
  );

  return (
    <section className="faq-section-container" id="faq">
      <div className="container position-relative">
        <div className="row">
          <div className="col-md-5 d-flex justify-content-start justify-content-lg-center align-items-center faq-left-col">
            <div className="faq-title-container-faq-section">
              <div className="faq-accent-svg-faq-section">
                <AccentSvgDesktop />
                <AccentSvgMobile />
              </div>
              <h2 className="faq-title-faq-section">{faqSectionTexts.title}</h2>
            </div>
          </div>
          <div className="col-md-7 faq-right-col">
            <div className="accordion-faq-section" id="faqAccordion">
              {faqSectionTexts.questions.map((faq, index) => (
                <div 
                  className={`accordion-item-faq-section ${isQuestionActive(index) ? 'active' : ''}`} 
                  key={index}
                >
                  <div
                    className={`accordion-header-faq-section ${isQuestionActive(index) ? 'active' : ''}`}
                    onClick={() => toggleQuestion(index)}
                  >
                    <h2 className="mb-0 text-start">
                      {faq.question}
                    </h2>
                    <span className="accordion-icon-faq-section">
                      {isQuestionActive(index) ? <UpIcon /> : <DownIcon />}
                    </span>
                  </div>
                  {isQuestionActive(index) && (
                    <div className="accordion-body-faq-section">
                      <p className="text-start p-2 accordion-body-faq-section-inner">
                        {faq.answer}
                      </p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="triangle-divider-faq"></div>
    </section>
  );
};

export default FaqSection;