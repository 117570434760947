import React from "react";
import { Helmet } from 'react-helmet-async';
import SectionOneFormAndHome from "./components/common_components/SectionOneFormAndHome";
import NavDesk from "./components/common_components/NavDesk";
import SectionTwoGoingTo from "./components/common_components/SectionTwoGoingTo";
import SectionThreeModuleAccordion from "./components/common_components/SectionThreeModuleAccordion";
import SectionFourTargetAudience from "./components/common_components/SectionFourTargetAudience";
import SectionFiveUpcomingSection from "./components/common_components/SectionFiveUpcomingSection";
import SectionSixFaqSection from "./components/common_components/SectionSixFaqSection";
import Footer from "./components/common_components/FooterProductManagement";


const LandingCiber = () => {
  return (
      <>
           <Helmet>
              <title>Máster en Ciberseguridad | Thinkia</title>
              <meta name="description" content="Adéntrate en el mundo de la ciberseguridad con el Máster en Ciberseguridad. Aprende a realizar auditorías, aplicar técnicas ofensivas y proteger redes y sistemas, incluyendo entornos en la nube y DevOps. Especialízate en un perfil en alta demanda en el mercado actual." />
          </Helmet>
          <SectionOneFormAndHome />
          <NavDesk />
          <SectionTwoGoingTo />
          <SectionThreeModuleAccordion />
          <SectionFourTargetAudience />
          <SectionFiveUpcomingSection />
          <SectionSixFaqSection />
          <Footer />
      </>
  );
}

export default LandingCiber;