import React from "react";

// single service
export function Vertical({ image, title, desc }) {
  return (
    <div className="col-xl-4 col-md-6 mb-4 mb-md-0">
      <div className="tp-service text-center white-bg pt-20 pb-20 pl-20 pr-20 mb-30">
        <div className="tp-service__image">
          <img src={image} alt={title} className="img-fluid" style={{ maxHeight: '60px', objectFit: 'contain' }}   />
        </div>
        <h3
          className="tp-service__title pt-40 pb-25"
          style={{ color: "#1A224C", fontFamily: "Poppins", fontWeight: 600 }}
        >
          {title}
        </h3>
        <p
          style={{
            color: "#1A224C",
            fontFamily: "DM Sans",
            fontSize: "18px",
            fontWeight: 400,
          }}
        >
          {desc}
        </p>
      </div>
    </div>
  );
}

const Services = () => {
  return (
    <>
      {/* <!-- service area start  --> */}
      <div
        id="landing-services-section"
        className="tp-service-ara pt-140 pb-140"
        style={{ backgroundColor: "#07081C" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center mb-70">
                <h2 className="tp-section-title text-white fw-light">
                  Verticales de negocio{" "}
                  <span style={{ color: "#00AFFF", fontWeight: 600 }}>
                    Thinkia
                  </span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <Vertical
              image="assets/img/icons/card1.svg"
              title="ARTIFICIAL INTELLIGENCE"
              desc="Implementamos soluciones de inteligencia artificial en función de las necesidades y el modelo de negocio de nuestros clientes, con el objetivo de optimizar procesos empresariales, generar un crecimiento sostenible y potenciar la competitividad dentro del mercado digital."
            />
            <Vertical
              image="assets/img/icons/card2.svg"
              title="STRATEGY & TECHNOLOGY"
              desc="Trabajamos continuamente en descubrir los insights clave que nos ayudan a definir la estrategia más adecuada para satisfacer las necesidades del negocio. Ayudamos nuestros clientes a enfrentarse al mundo digital, optimizando productos y servicios desde la estrategia basada en la tecnología."
            />
            <Vertical
              image="assets/img/icons/card3.svg"
              title="OPERATIONAL INTELLIGENCE"
              desc="Optimizamos los procesos de producción, gestión integral de venta online y automatización de cadenas de suministro, apoyándonos en la IA. Buscamos la excelencia en cualquier proceso operativo mediante metodologías vanguardistas y las tecnologías mas punteras."
            />
          </div>
        </div>
      </div>
      {/* <!-- service area end  -->   */}
    </>
  );
};

export default Services;
