import React from "react";
import "../css/SectionTwo.scss";
import imagesectiontwo from "src/assets/img/DevOps2.jpeg";
import { sectionTwoTexts } from "../data/textContext";

const SectionTwo = () => {
  const { mainSection, learnSection } = sectionTwoTexts;

  const StarIcon = () => (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
          <g clipPath="url(#clip0_2903_383)">
              <path d="M16.2349 9.12612L19.9997 10.2777L16.274 11.3619C15.2225 11.6679 14.4275 12.5205 14.1947 13.5917L13.0065 19.0596L11.7257 13.5582C11.477 12.4902 10.6767 11.6283 9.62917 11.3002L5.99902 10.1634L9.63176 9.07959C10.663 8.77193 11.4445 7.93728 11.6846 6.88721L12.8648 1.724L14.1265 6.89393C14.3854 7.95521 15.1895 8.80648 16.235 9.12624L16.2349 9.12612Z" fill="#00AFFF" />
              <path d="M7.05671 14.3968L9.65218 15.1906L7.08367 15.9381C6.35877 16.149 5.81061 16.7368 5.65015 17.4753L4.83101 21.2449L3.94801 17.4521C3.77657 16.7159 3.22488 16.1217 2.5027 15.8955L0 15.1117L2.50441 14.3646C3.21539 14.1524 3.75417 13.577 3.91964 12.8531L4.73335 9.29352L5.60309 12.8577C5.7816 13.5894 6.33595 14.1763 7.05671 14.3967V14.3968Z" fill="#00AFFF" />
              <path d="M8.10256 3.7373L10.0034 4.31871L8.12231 4.8661C7.59144 5.02061 7.19001 5.45106 7.07247 5.99184L6.4726 8.75249L5.8259 5.97491C5.70035 5.43572 5.29632 5.0005 4.76739 4.83491L2.93457 4.26092L4.76869 3.71371C5.28936 3.55837 5.68395 3.13695 5.80514 2.60679L6.40106 0L7.03803 2.61021C7.16878 3.14604 7.57475 3.57583 8.10256 3.7373Z" fill="#00AFFF" />
          </g>
          <defs>
              <clipPath id="clip0_2903_383">
                  <rect width="20" height="21.2448" fill="white" />
              </clipPath>
          </defs>
      </svg>
  );

  return (
      <div className="new-section-landing-product-management" id="objetivo">
          <div className="container">
              <div className="row first-row-landing-product-management">
                  <div className="col-md-5 px-3 px-md-0">
                      <div className="col-12">
                          <h2 className="landing-product-management-title">
                              <span style={{ paddingBottom: '30px', display: 'inline-block' }}>
                                  {mainSection.title.firstPart}
                              </span>
                              <br />
                              {mainSection.title.secondPart}
                          </h2>
                      </div>
                  </div>
                  <div className="col-md-7 d-flex flex-row px-0 justify-content-center mt-md-0 mt-5">
                      {mainSection.stats.map((stat, index) => (
                          <div key={index} className="col-4 text-center d-flex justify-content-center justify-content-md-end">
                              <div className="info-box">
                                  <div className="info-box-number">
                                      <h2 className="figures-landing-product-management">{stat.number}</h2>
                                  </div>
                                  <div className="info-box-text">
                                      <p className="weeks-landing-product-management mt-1 mb-2">{stat.label}</p>
                                  </div>
                              </div>
                          </div>
                      ))}
                  </div>
              </div>
          </div>

          <div className="container learn-information-container">
              <div className="row">
                  <div className="col-12 text-center mt-3 mb-2 mb-md-4">
                      <h2 className="learn-information-title">{learnSection.title}</h2>
                  </div>
              </div>
              <div className="row mt-2 second-row-landing-product-management d-flex justify-content-center">
                  <div className="col-md-5 d-flex justify-content-center">
                      <img
                          src={imagesectiontwo}
                          alt="Laptop con gráficos"
                          className="img-fluid image-sectionTwo-container"
                      />
                  </div>
                  <div className="col-md-5 learnsections-groups">
                      {learnSection.benefits.map((benefit, index) => (
                          <div key={index} className="information-section d-flex flex-row justify-content-start align-content-center align-items-center">
                              <div className="svg-information-section">
                                  <StarIcon />
                              </div>
                              <div className={`text-information-section ${benefit.specialHeight ? 'special-height' : ''}`}>
                                  <p className="mb-0 text-information-section-inner">{benefit.text}</p>
                              </div>
                          </div>
                      ))}
                  </div>
              </div>
          </div>
          <div className="triangle-divider"></div>
      </div>
  );
};

export default SectionTwo;