export const homeAndFormTexts = {
    formSection: {
        name: "Nombre *",
        phone: "Teléfono *",
        email: "Correo *",
        message: "Si tienes dudas escríbenos aquí tu consulta ...",
        rgpdText: "Acepto el tratamiento de datos y políticas de privacidad de Thinkia (Search Engine Business S.L.).*",
        informationButton: "Quiero más información"
    }
};
export const sectionTwoTexts = {
    mainSection: {
        title: {
            firstPart: "Aprende de la mano de destacados profesionales del sector, en activo y con contrastada experiencia docente.",
            secondPart: "Adquiere nuevos conocimientos y las habilidades que demandan las empresas."
        },
        stats: [
            {
                number: "12",
                label: "SEMANAS"
            },
            {
                number: "120",
                label: "HORAS"
            },
            {
                number: "8",
                label: "DOCENTES EXPERTOS"
            }
        ]
    },
    learnSection: {
        title: "¿Qué vas a conseguir?",
        benefits: [
            {
                text: "Realización de auditorías de seguridad y evaluación de la protección organizacional."
            },
            {
                text: "Conocimiento de técnicas ofensivas en el sector de la ciberseguridad.",
                
            },
            {
                text: "Fortificación de redes y sistemas para mejorar la seguridad."
            },
            {
                text: "Protección de entornos en la nube y requisitos mínimos en el contexto DevOps.",
                
            },
            {
                text: "Especialización en un perfil con creciente relevancia y demanda."
            }
        ]
    }
};

export const accordionTexts = {
    title: "Contenido del Máster",
    modules: [
        {
            number: 'Módulo 01',
            title: 'Ciberseguridad y Seguridad Informática',
            content: [
                {
                    list: [
                        'Definición y diferenciación de seguridad de la información, seguridad informática y ciberseguridad.',
                        'Conceptos básicos de auditoría, recopilación de información (OSINT) y análisis de metadatos.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 02',
            title: 'Ciberinteligencia ',
            content: [
                {
                    list: [
                        'Metodologías y sistemas de ciberinteligencia.',
                        'Técnicas y casos prácticos en entornos reales.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 03',
            title: 'Auditoría y Hacking de Sistemas',
            content: [
                {

                    list: [
                        'Técnicas modernas de auditoría y hacking ético, incluyendo recopilación de información, análisis de activos, detección y explotación de vulnerabilidades.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 04',
            title: 'Auditoría y Hacking Web',
            content: [
                {
                    list: [
                        'Evolución de vulnerabilidades web y técnicas de ataque',
                        'Reconocimiento, descubrimiento y explotación de vulnerabilidades en aplicaciones web.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 05',
            title: 'Evaluación de Seguridad en la Red',
            content: [
                {
                    list: [
                        'Análisis de tráfico y riesgos en redes locales, Internet y entornos Cloud.',
                        'Identificación y mitigación de amenazas en redes IPv4 e IPv6.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 06',
            title: 'Auditoría de Redes Inalámbricas',
            content: [
                {
                    list: [
                        'Seguridad en redes inalámbricas personales y empresariales.',
                        'Técnicas de ataque y pruebas de auditoría en redes inalámbricas.'
                    ]

                }
            ]
        },
        {
            number: 'Módulo 07',
            title: 'Hardening de Redes Empresariales',
            content: [
                {
                    list: [
                        'Fortificación de redes corporativas, incluyendo direccionamiento, segmentación y configuración de sistemas IDS/IPS.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 08',
            title: 'Hardening de Servidores',
            content: [
                {
                    list: [
                        'Protección de servidores Windows y GNU/Linux.',
                        'Principios de mínimo privilegio, mínima exposición y defensa en profundidad.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 09',
            title: 'Criptografía Aplicada a la Ciberseguridad',
            content: [
                {
                    list: [
                        'Aplicación de métodos criptográficos en la seguridad empresarial.',
                        'Certificación, autenticación, firma digital y cifrado de correos electrónicos.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 10',
            title: 'Exploiting e Ingeniería Inversa',
            content: [
                {
                    list: [
                        'Técnicas avanzadas de explotación y análisis de programas.',
                        'Ingeniería inversa, buffer overflow, y bypass de protecciones como DEP y ASLR.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 11',
            title: 'Forense Digital y Respuesta ante Incidentes',
            content: [
                {
                    list: [
                        'Proceso de análisis forense y respuesta a incidentes de seguridad.',
                        'Adquisición, análisis de evidencias y tipos de forense.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 12',
            title: 'Seguridad en Entornos Cloud',
            content: [
                {
                    list: [
                        'Fundamentos de seguridad en la nube, incluyendo arquitectura, gestión de identidad, cifrado, protección contra ataques y recuperación ante desastres.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 13',
            title: 'SecDevOps',
            content: [
                {
                    list: [
                        'Integración de seguridad en los procesos DevOps.',
                        'Uso de Docker y Kubernetes para fortificación y seguridad de contenedores.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 14',
            title: 'Introducción a la Web 3',
            content: [
                {
                    list: [
                        'Fundamentos y seguridad en tecnología blockchain y aplicaciones descentralizadas (dApps).',
                        'Contratos inteligentes, vulnerabilidades y mejores prácticas de seguridad en Web 3.'
                    ]
                }
            ]
        },
        {
            number: 'Módulo 15',
            title: 'Proyecto Final',
            content: [
                {
                    description: 'Aplicación práctica de los conocimientos adquiridos en un entorno real con pruebas de intrusión, vulnerabilidades web y escalada de privilegios.'
                }
            ]
        }
    ]
};
export const targetAudienceTexts = {
    mainTitle: "¿A quién va dirigido?",
    prerequisites: {
        title: "Pre-requisitos:",
        list: [ 
            "Conocimiento básico de redes informáticas y arquitectura TCP/IP.",
            "Conocimientos básicos de Sistemas Operativos (Windows / Linux)."
        ]
    },
    profiles: {
        title: "Orientado a perfiles como",
        list: [
            "Empresas con la necesidad de formar a su equipo IT para evitar riesgos y mejorar la defensa de sus sistemas.",
            "Desarrolladores con interés en ciberseguridad como campo en crecimiento con alta demanda y oportunidad de avance profesional.",
            "Fuerzas de seguridad con intención de aprender y comprender el área de ciberseguridad.",
            "Administradores que buscan comprender los riesgos en infraestructuras y redes.",
            "Estudiantes que con intención de especialización en un campo con alta demanda de profesionales.",
            "Perfiles profesionales: Técnicos en Ciberseguridad, Analistas de seguridad, Pentesters, Administradores de seguridad, Analistas forenses, Auditores de seguridad"
        ]
    },
    features: [
        {
            iconAlt: "Icono casa",
            text: "Desde Casa"
        },
        {
            iconAlt: "Icono Másterclass",
            text: "Másterclasses en directo"
        },
        {
            iconAlt: "Icono expertos",
            text: "Profesionales expertos en activo"
        }
    ]
};
export const upcomingSectionTexts = {
    mainTitle: "Próximas Convocatorias",
    edition: {
        items: [
            {
                text: "I Edición"
            },
            {
                text: "28 Marzo 2025"
            },
            {
                text: "Masterclass en directo\nViernes y Sábados"
            },
            {
                text: "Vie. 15:00 a 20:00h\nSáb. 9:00 a 14:00h"
            }
        ]
    },
    pricing: {
        label: "Precio",
        amount: "3.400€",
        buttonText: "Quiero más información"
    },
    details: {
        title: "Detalles del Máster",
        list: [
            "12 semanas - 120 horas",
            "8 docentes en activo",
            "Certificación al finalizar",
            "Masterclass en directo"
        ]
    }
};
export const faqSectionTexts = {
    title: "Preguntas Frecuentes",
    questions: [
        {
            question: "¿La formación es en directo?",
            answer: "Sí, hemos ideado nuestro bootcamp en Product Management como una formación completamente online pero sin perder la esencia de poder vivirla compartiendo la experiencia en directo con tus compañeros y profesores. Así pues, cada clase será en directo para que puedas preguntar tus dudas a tus profesores y hablar con tus compañeros."
        },
        {
            question: "¿Cómo puedo acceder al Máster?",
            answer: "Es tan fácil como completar la solicitud con tus datos y nos pondremos rápidamente en contacto contigo. Agendaremos una entrevista para conocer tus intereses y ayudarte a alcanzar tu objetivo."
        },
        {
            question: "¿Qué requisitos hacen falta?",
            answer: "Partirás con una base si tienes conocimiento sobre producto, desarrollo, data, marketing o negocio, pero no son requerimientos obligatorios."
        },
        {
            question: "¿Qué perfil debo tener para poder acceder?",
            answer: "No existe un perfil ideal ni un background concreto para llegar a ser un buen/a PM, algunos/as llegan desde el mundo de la programación, marketing o negocio por lo que te será más sencillo si provienes de alguna de estas ramas. Aún así, no es necesario tener nociones previas en estas áreas. Nuestro equipo de Admisiones realizará una entrevista contigo para conocerte mejor y poder asesorarte en tu carrera profesional."
        },
        {
            question: "¿Por qué elegir este Máster y no otro?",
            answer: "En Thinkia Training hemos creado un máster 100% online con Masterclass en directo cada viernes y sábado en el que adquirás de la mano de profesionales en activo que tienen experiencia con este rol, y estarás en contacto con compañeros en un punto profesional similar al tuyo, y con las mismas inquietudes. Estamos totalmente comprometidos con tu formación, por eso hemos diseñado un programa adaptado a las necesidades actuales de las empresas con profesores que son profesionales de alto nivel en activo."
        },
        {
            question: "¿Lo puedo hacer mientras estoy trabajando?",
            answer: "El horario está adaptado al horario laboral para que puedas compaginar tu trabajo con tu formación. Además, al tratarse de una formación online puedes conectarte desde cualquier lugar a la hora de la Masterclass y pondremos a tu disposición la documentación que aporte cada profesor en nuestra plataforma formativa. Y si algún día no puedes conectarte las sesiones se grabarán para verlas luego. o poder repasar la clase con el experto."
        }
    ]
};

const textContextData = {
    homeAndFormTexts,
    sectionTwoTexts,
    accordionTexts,
    targetAudienceTexts,
    upcomingSectionTexts,
    faqSectionTexts
};

export default textContextData;