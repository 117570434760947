import React, { useLayoutEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/common_ccs/HeaderLegal.css';
import Footer from './FooterProductManagement';
import LogoThinkia from '../../assets/logos/LogoThinkiaTraining.png';
import { Link } from 'react-router-dom';

const Privacidad = () => {

    useLayoutEffect(() => {
        document.documentElement.style.scrollBehavior = 'auto';
        window.scrollTo(0, 0);
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
        
        return () => {
            document.documentElement.style.scrollBehavior = '';
        };
    }, []);

    return (
        <div className="main-container">
            <header className="d-flex justify-content-between align-items-center p-4 text-white header-container-aviso-legal">
            <div className="logo px-md-5">
                    <Link to="/"><img src={LogoThinkia} alt="Logotipo" className="logo-landing-product-management img-fluid" /></Link>
                </div>
                <div className="button-aviso-legal px-md-5">
                    <button className="btn btn-primary button-aviso-legal border-5 rounded-pill">Contacto</button>
                </div>
            </header>
            <div className="avisos-legales-body px-2">
                <div className="container">
                    <div className="row mt-4 mb-4">
                        <div className="col-md-12 d-flex flex-row justify-content-center">
                            <h1 className="aviso-legal-main-title">Política de Privacidad</h1>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            <h3 className="privacidad-main-title my-3">Política de Privacidad y Protección de Datos</h3>
                            <p className="aviso-legal-description-privacidad mb-4">Bienvenido al sitio web de SEARCH ENGINE BUSINESS SL, en adelante THINKIA. En esta sección explicamos nuestra política de privacidad y seguridad en el ámbito de la protección de datos personales. También le informamos de sus derechos y detallamos las medidas de seguridad que aplicamos a los datos que usted nos proporciona al visitar nuestro sitio web, al contratar nuestros productos o servicios, o de cualquier otra forma legítima. Cualquier cambio en nuestra política será comunicado puntualmente y las actualizaciones se publicarán en nuestro sitio web.</p>
                            <h3 className="privacidad-main-title my-3">¿Quién es responsable del tratamiento de sus datos?</h3>
                            <ul className="aviso-legal-description-list list-unstyled mb-4">
                                <li className="">La empresa responsable del tratamiento de los datos personales es: SEARCH ENGINE BUSINESS SL</li>
                                <li>CIF: B01764703</li>
                                <li>Domicilio: Calle Velázquez, 28 3º 28001 Madrid</li>
                                <li>Correo electrónico: info@searchengine.group</li>
                                <li>Web: https://thinkia.com/</li>
                            </ul>
                            <h3 className="privacidad-main-title my-3">Información de contacto</h3>
                            <p className="aviso-legal-description-privacidad mb-4">THINKIA le ofrece distintos canales de contacto en relación a las consultas sobre privacidad que desee realizar. Puede también comunicarse con nosotros por teléfono o a través de la sección “contacto” en nuestro sitio web.</p>
                            <h3 className="privacidad-main-title my-3">¿Cuáles son nuestros principios en el tratamiento de datos personales?</h3>
                            <p className="aviso-legal-description-privacidad mb-4">El respeto a su privacidad es fundamental para THINKIA, por lo que le informamos de manera transparente sobre los tratamientos que podemos realizar con sus datos personales para que pueda tomar una decisión al respecto. Le pedimos que dedique unos minutos a leer esta Política de Privacidad.</p>
                            <p className="aviso-legal-description-privacidad">El objetivo principal de nuestra política es garantizar el pleno cumplimiento de la normativa vigente en materia de protección de datos y la privacidad y seguridad en el tratamiento de sus datos personales.</p>
                            <p className="aviso-legal-description-privacidad">En el tratamiento de los datos personales facilitados por usted, THINKIA se rige, entre otros, por los principios de Transparencia, Licitud, Responsabilidad Proactiva, Minimización de Datos, Pseudonimización y Eliminación.</p>
                            <h3 className="privacidad-main-title my-3">¿Para qué finalidad tratamos sus datos personales?</h3>
                            <p className="aviso-legal-description-privacidad">THINKIA utilizará sus datos personales para los siguientes fines:</p>
                            <ul className="aviso-legal-description-list mb-4">
                                <li className="">Tratar sus datos conforme al consentimiento que nos haya otorgado, cuando THINKIA no esté amparado en otra base jurídica. Como, por ejemplo, enviar publicidad de terceros con los que THINKIA tenga acuerdos comerciales, tratar sus datos de navegación, facturación con fines comerciales o utilizar información anónima y agregada generada a partir de los suyos para análisis informativo (Big Data). En cualquier caso, puede revocar el consentimiento en cualquier momento.</li>
                                <li>La prestación de servicios y productos de nuestra empresa, formalizar contratos y permitir su ejecución posterior, lo cual puede variar según el servicio o producto en cuestión. Esto nos permite ofrecerle el mejor servicio solicitado y mantener la comunicación según sus intereses comerciales o su visita a nuestro sitio. También necesitamos realizar verificaciones de datos y crédito para permitir la contratación de nuestros servicios o productos, así como para tomar acciones de recuperación de deudas en caso de impagos.</li>
                                <li>Satisfacer los intereses comerciales legítimos de THINKIA, tales como la prevención de fraudes, el mantenimiento de la seguridad de nuestra red y servicios, el envío de comunicaciones comerciales sobre nuestros productos y servicios y la mejora de los mismos. En estos casos, evaluamos nuestros intereses comerciales para asegurar que no colisionen con sus derechos. Además, en ciertos casos, tiene derecho a oponerse a estos tratamientos, y en esta política recopilamos todos los derechos que le protegen.</li>
                                <li>Cumplir con una obligación legal, como cumplir con obligaciones contables y fiscales, o retener datos de tráfico para cumplir con la legislación vigente en materia de conservación de datos, entre otros.</li>
                            </ul>
                            <p className="aviso-legal-description-bold">Marketing y generación de perfiles</p>
                            <p className="aviso-legal-description-privacidad">Si es nuestro cliente, THINKIA tiene un interés legítimo en mantenerle informado sobre nuevos productos y servicios. Adaptamos estos mensajes en función de los productos y servicios contratados. Podemos enviarle boletines informativos, invitarle a participar en encuestas o informarle sobre sorteos o concursos, cuando sea aplicable.</p>
                            <p className="aviso-legal-description-bold">
                            Publicidad en línea</p>
                            <p className="aviso-legal-description-privacidad">Para proporcionarle información comercial relevante, es posible que visualice publicidad dirigida en línea basada en el uso de cookies en nuestro sitio web, otras organizaciones y medios en línea como redes sociales. También podemos combinar los datos obtenidos a través de cookies con otros datos recopilados. Si no desea que la información obtenida a través de cookies sea utilizada, consulte nuestra Política de Cookies para excluirlas.</p>
                            <p className="aviso-legal-description-bold">Investigación y análisis (Big Data)</p>
                            <p className="aviso-legal-description-privacidad">A través de la técnica de "Big Data," analizamos grandes cantidades de datos para identificar patrones y tendencias, respetando plenamente la ley y el principio de transparencia. En estos análisis solo usamos datos anonimizados y agregados, de modo que no es posible asociar esta información a ninguno de nuestros clientes. Puede, en cualquier momento, expresar su deseo de que sus datos no sean considerados en iniciativas de Big Data escribiendo a info@searchengine.group.</p>
                            <h3 className="privacidad-main-title my-3">¿Por cuánto tiempo conservaremos sus datos?</h3>
                            <p className="aviso-legal-description-privacidad">Los datos proporcionados se conservarán mientras el interesado no solicite su supresión. Todas nuestras comunicaciones comerciales incluyen la opción de darse de baja. Si ejerce la opción de “baja”, sus datos serán eliminados de nuestro archivo general de comunicaciones.</p>
                            <h3 className="privacidad-main-title my-3">¿Cuál es la legitimación para el tratamiento de sus datos?</h3>
                            <p className="aviso-legal-description-privacidad">La base legal para el tratamiento de sus datos puede incluir:</p>
                            <ul className="aviso-legal-description-list mb-4">
                                <li className="">El registro en nuestros sistemas para solicitar información sobre un producto o servicio o para contratarlo o usarlo;</li>
                                <li>La compra o uso de cualquiera de nuestros productos y servicios;</li>
                                <li>La suscripción a boletines informativos, alertas u otros servicios;</li>
                                <li>El contacto a través de nuestros canales;</li>
                                <li>La participación en un concurso, sorteo o encuesta;</li>
                                <li>La navegación por nuestro sitio web o por otros sitios de THINKIA;</li>
                                <li>Su autorización a terceros, como nuestros colaboradores o proveedores, para compartir su información con nosotros;</li>
                                <li>La disponibilidad pública de la información y su uso permitido legalmente;</li>
                                <li>Ser cliente de un negocio o entidad que THINKIA haya adquirido.</li>
                            </ul>
                            <h3 className="privacidad-main-title my-3">Información que recopilamos</h3>
                            <p className="aviso-legal-description-privacidad">THINKIA puede recopilar, tras su consentimiento y según las finalidades especificadas, datos como:</p>
                            <ul className="aviso-legal-description-list mb-4">
                                <li className="">Su nombre, dirección, teléfono y/o móvil, fecha de nacimiento, género y correo electrónico;</li>
                                <li>Información de su cuenta bancaria y datos financieros necesarios;</li>
                                <li>Información obtenida a través del contacto con nosotros y grabación de llamadas;</li>
                                <li>Información de su cuenta como cliente, suscripciones y pagos;</li>
                                <li>Credenciales de acceso como contraseñas y nombres de usuario;</li>
                                <li>Preferencias de productos o servicios que nos haya comunicado;</li>
                                <li>Historial de navegación, tras su autorización;</li>
                                <li>Información de otras fuentes, como agencias de crédito, prevención de fraudes y otros proveedores de datos.</li>
                            </ul>
                            <h3 className="privacidad-main-title my-3">¿A qué destinatarios se comunicarán sus datos?</h3>
                            <p className="aviso-legal-description-privacidad">Los datos personales que nos ha confiado podrán ser transmitidos a:</p>
                            <ul className="aviso-legal-description-list mb-4">
                                <li className="">Proveedores implicados en la distribución de los productos y servicios solicitados o utilizados;</li>
                                <li>Empresas contratadas o colaboradoras de THINKIA para la prestación de servicios;</li>
                                <li>Agencias de referencia crediticia, prevención de fraudes o agencias de clasificación empresarial;</li>
                                <li>Administraciones públicas estatales, autonómicas o locales para cumplimiento de obligación legal;</li>
                                <li>Terceros, cuando sea necesario cumplir con la ley aplicable o con requisitos legales o judiciales.</li>
                            </ul>
                            <h3 className="privacidad-main-title my-3">¿Cuáles son sus derechos al facilitarnos sus datos?</h3>
                            <p className="aviso-legal-description-privacidad">Usted tiene derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, su supresión. En ciertas circunstancias, podrá solicitar la limitación del tratamiento de sus datos o presentar una objeción. THINKIA cesará el tratamiento de los datos, salvo por motivos legítimos o de defensa de posibles reclamaciones.</p>
                            <p className="aviso-legal-description-privacidad">Puede ejercer estos derechos notificando al Responsable del tratamiento a info@searchengine.group e incluyendo una copia de su DNI o pasaporte. THINKIA responderá en un plazo máximo de un mes, pudiendo extenderse dos meses adicionales en caso de complejidad o volumen de solicitudes.</p>
                            <h3 className="privacidad-main-title my-3">¿Están seguros sus datos personales?</h3>
                            <p className="aviso-legal-description-privacidad">Hemos adoptado las medidas técnicas y organizativas necesarias para garantizar la seguridad de los datos personales proporcionados, protegiéndolos de alteraciones, pérdida, uso indebido, divulgación y accesos no autorizados, conforme a las normativas vigentes.</p>
                            <h3 className="privacidad-main-title my-3">Transferencias Internacionales de Datos</h3>
                            <p className="aviso-legal-description-privacidad">Podemos necesitar transferir su información a otras empresas de THINKIA o a proveedores de servicios en países fuera de la Comunidad Económica Europea (CEE). Si THINKIA envía su información a un país que no forma parte de la CEE, nos aseguraremos de que esté debidamente protegida y de que exista un contrato adecuado que cubra la transferencia de datos. En caso necesario, solicitaremos autorización previa a la Agencia Española de Protección de Datos.</p>
                            <div className="hidde-container d-none d-md-block"></div>

                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <Footer />
            </footer>
        </div>
    );
};

export default Privacidad;
