import React from "react";
import { Helmet } from 'react-helmet-async';
import SectionOneFormAndHome from "./components/SectionOneFormAndHome";
import NavDesk from "./components/NavDesk";
import SectionTwoGoingTo from "./components/SectionTwoGoingTo";
import SectionThreeModuleAccordion from "./components/SectionThreeModuleAccordion";
import SectionFourTargetAudience from "./components/SectionFourTargetAudience";
import SectionFiveUpcomingSection from "./components/SectionFiveUpcomingSection";
import SectionSixFaqSection from "./components/SectionSixFaqSection";
import Footer from "./components/FooterProductManagement";


const LandingDevOps = () => {
  return (
      <>
           <Helmet>
              <title>Máster en DevOps | Thinkia </title>
              <meta name="description" content="Domina las herramientas y estrategias clave con el Máster en DevOps. Aprende sobre arquitectura de sistemas, gestión de servicios en la nube, continuous delivery y orquestación. Especialízate en un perfil altamente demandado en el mercado." />
          </Helmet>
          <SectionOneFormAndHome />
          <NavDesk />
          <SectionTwoGoingTo />
          <SectionThreeModuleAccordion />
          <SectionFourTargetAudience />
          <SectionFiveUpcomingSection />
          <SectionSixFaqSection />
          <Footer />
      </>
  );
}

export default LandingDevOps;