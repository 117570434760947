import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/ModuleAccordion.css';
import AnimatedDownloadButton from './AnimatedDownloadButton';
import { accordionTexts } from '../../data/textContext';

const ModuleAccordion = () => {
    const [activeModules, setActiveModules] = useState([]);

    const toggleModule = (moduleIndex) => {
        setActiveModules((prevActiveModules) =>
            prevActiveModules.includes(moduleIndex)
                ? prevActiveModules.filter((index) => index !== moduleIndex) 
                : [...prevActiveModules, moduleIndex] 
        );
    };

    const UpIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
            <path d="M16.2504 25.5012L13.7504 25.5012L13.7504 10.5012L6.87539 17.3762L5.10039 15.6012L15.0004 5.70117L24.9004 15.6012L23.1254 17.3762L16.2504 10.5012L16.2504 25.5012Z" fill="#00AFFF" />
        </svg>
    );

    const DownIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
            <path d="M13.7496 5.49883L16.2496 5.49883L16.2496 20.4988L23.1246 13.6238L24.8996 15.3988L14.9996 25.2988L5.09961 15.3988L6.87461 13.6238L13.7496 20.4988L13.7496 5.49883Z" fill="#00AFFF" />
        </svg>
    );

    return (
        <section className="accordion-section-contaniner-main" id="contenido">
            <div className="container">
                <h2 className="text-center accordion-title-desktop-version">{accordionTexts.title}</h2>
                <div className="accordion mt-4 px-2" id="modulesAccordion">
                    {accordionTexts.modules.map((module, index) => (
                        <div className="card-accordion" key={index}>
                            <div
                                className="card-header-accordion d-flex justify-content-between align-items-center p-2"
                                id={`heading${index}`}
                                onClick={() => toggleModule(index)} 
                                style={{ cursor: 'pointer' }}
                            >
                                <h2 className="mb-0 d-none d-md-block">
                                    <span className="module-number">{module.number}</span>
                                    &nbsp;
                                    <span className="module-title">{module.title}</span>
                                </h2>
                                <h2 className="mb-0 d-block d-md-none">
                                    <span className="module-number">{module.number}</span>
                                    <br />
                                    <span className="module-title">{module.title}</span>
                                </h2>
                                <span className="accordion-toggle-icon">
                                    {activeModules.includes(index) ? <UpIcon /> : <DownIcon />}
                                </span>
                            </div>

                            <div
                                id={`collapse${index}`}
                                className={`collapse ${activeModules.includes(index) ? 'show' : ''}`}
                                aria-labelledby={`heading${index}`}
                            >
                                <div className="card-body-accordion px-md-5 py-md-3 px-3 py-3">
                                    {module.content.map((item, itemIndex) => (
                                        <div key={itemIndex}>
                                            {item.title && (
                                                <p className="accordion-content-title-inner mb-1">
                                                    {item.title}
                                                </p>
                                            )}
                                            <p className="Accordion-content-description-inner">
                                                {item.description}
                                            </p>
                                            {item.list && item.list.length > 0 && (
                                                <ul className="mt-2 mb-3 ps-4 Accordion-content-description-inner">
                                                    {item.list.map((listItem, listIndex) => (
                                                        <li key={listIndex} className="mb-1">
                                                            <div dangerouslySetInnerHTML={{ __html: listItem }} />
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="text-center mt-4 accordion-button-download-container px-2 px-md-0">
                    <AnimatedDownloadButton />
                </div>
            </div>
            <div className="triangle-divider-accordion"></div>
        </section>
    );
};

export default ModuleAccordion;
