import React from "react";
import { Helmet } from 'react-helmet-async';
import SectionOneFormAndHome from "./components/SectionOneFormAndHome";
import NavDesk from "./components/NavDesk";
import SectionTwoGoingTo from "./components/SectionTwoGoingTo";
import SectionThreeModuleAccordion from "./components/SectionThreeModuleAccordion";
import SectionFourTargetAudience from "./components/SectionFourTargetAudience";
import SectionFiveUpcomingSection from "./components/SectionFiveUpcomingSection";
import SectionSixFaqSection from "./components/SectionSixFaqSection";
import Footer from "./components/FooterProductManagement";

const LandingProduct = () => {
  return (
    <>
      <Helmet>
        <title>Máster en Product Management | Thinkia</title>
        <meta name="description" content="Conviértete en experto en desarrollo de productos digitales con el Máster en Product Management. Aprende a gestionar el ciclo de vida del producto, tomar decisiones basadas en métricas y trabajar con equipos tecnológicos. Domina las habilidades clave de un Product Manager y prepárate para destacar en entrevistas." />
      </Helmet>
      <SectionOneFormAndHome />
      <NavDesk />
      <SectionTwoGoingTo />
      <SectionThreeModuleAccordion />
      <SectionFourTargetAudience />
      <SectionFiveUpcomingSection />
      <SectionSixFaqSection />
      <Footer />
    </>
  );
}

export default LandingProduct;