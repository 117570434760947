import firebase from 'firebase/compat/app'
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAkWbEjABqwAE6WZuX18rNKIn0-E87BfxE",
  authDomain: "web-corporativa-423508.firebaseapp.com",
  projectId: "web-corporativa-423508",
  storageBucket: "web-corporativa-423508.firebasestorage.app",
  messagingSenderId: "8609869744",
  appId: "1:8609869744:web:5aa44cb7a4e88ebd143075",
  measurementId: "G-PK6PFRYJ00",
};

const app = firebase.apps.length
  ? firebase.app()
  : firebase.initializeApp(firebaseConfig)

const db = getFirestore(app);
export { app, db };
