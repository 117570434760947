import React, { useState, useEffect, useRef } from 'react';
import '../css/NavDesk.scss';

const NavDesk = () => {
    const [isSticky, setIsSticky] = useState(false);
    const [offsetTop, setOffsetTop] = useState(null);
    const navRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (navRef.current) {
                const currentOffsetTop = window.scrollY;
                const navPosition = offsetTop ?? navRef.current.offsetTop;
    
                if (currentOffsetTop > navPosition && !isSticky) {
                    setIsSticky(true);
                } else if (currentOffsetTop <= navPosition && isSticky) {
                    setIsSticky(false);
                }
            }
        };
    
        if (navRef.current && offsetTop === null) {
            setOffsetTop(navRef.current.offsetTop);
        }
    
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [offsetTop, isSticky]);
    

    return (
        <section
            ref={navRef}
            className={`navdesk-container mt-0 d-none d-lg-block ${isSticky ? 'sticky' : ''}`}
        >
            <div className="mt-0 pt-0">
                <div className="row">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <button
                                className="navbar-toggler"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarNav"
                                aria-controls="navbarNav"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav w-100 gap-3 justify-content-around">
                                    <li className="nav-item">
                                        <a className="nav-link" href="#objetivo">Objetivo</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#contenido">Contenido</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#paraquien">Para Quién</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#precio">Precio</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#faq">Preguntas Frecuentes</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </section>
    );
};

export default NavDesk;
