import React from "react";
import { Helmet } from 'react-helmet-async';
import SectionOneFormAndHome from "./components/SectionOneFormAndHome";
import NavDesk from "./components/NavDesk";
import SectionTwoGoingTo from "./components/SectionTwoGoingTo";
import SectionThreeModuleAccordion from "./components/SectionThreeModuleAccordion";
import SectionFourTargetAudience from "./components/SectionFourTargetAudience";
import SectionFiveUpcomingSection from "./components/SectionFiveUpcomingSection";
import SectionSixFaqSection from "./components/SectionSixFaqSection";
import Footer from "./components/FooterProductManagement";

const LandingAgile = () => {
  return (
    <>
      <Helmet>
        <title>Máster en Agile Management | Thinkia</title>
        <meta name="description" content="Domina los métodos ágiles con el Máster en Agile Management. Aprende a implementar estrategias efectivas en productos y servicios, optimizar el rendimiento de equipos, y mejorar la colaboración en proyectos de tecnología. Desarrolla habilidades prácticas con casos reales de grandes empresas." />
      </Helmet>
      <SectionOneFormAndHome />
      <NavDesk />
      <SectionTwoGoingTo />
      <SectionThreeModuleAccordion />
      <SectionFourTargetAudience />
      <SectionFiveUpcomingSection />
      <SectionSixFaqSection />
      <Footer />
      </>
  );
}

export default LandingAgile;