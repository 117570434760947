import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import LogoThinkia from '../assets/logos/LogoFooter.png';
import '../assets/css/common_ccs/FooterProductManagement.scss';

const Footer = () => {
    return (
        <footer className="footer-container">
            <div className="footer-logo-container text-center">
                <img src={LogoThinkia} alt="Thinkia Logo" className="footer-logo" />
            </div>
            <div className="footer-line d-none d-md-block"></div>
            <div className="footer-links text-center">
                <a href="/aviso-legal" target="_blank" rel="noopener noreferrer" className="footer-link mx-2">Aviso Legal</a> | 
                <a href="/privacidad" target="_blank" rel="noopener noreferrer" className="footer-link mx-2">Política de Privacidad</a> | 
                <a href="/cookies" target="_blank" rel="noopener noreferrer" className="footer-link mx-2">Política de Cookies</a> 
            </div>
        </footer>
    );
};

export default Footer;

