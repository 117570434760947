import React from "react";
import Footer from "src/components/common/Footer/Footer";
import Menu from "src/components/common/Menu/Menu";
import Hero from "src/components/Home/Hero";
import QuienesSomos from "src/components/Home/QuienesSomos";
import TopAbout from "src/components/Home/TopAbout";
import Services from "src/components/Home/Verticales";
import Presentes from "src/components/Home/Presentes";
import Disenyamos from "src/components/Home/Disenyamos";
import Compania from "src/components/Home/Compania";
import Futuro from "src/components/Home/Futuro";
import QueHacemos from "src/components/Home/QueHacemos";
import Lupa from "src/components/Home/Lupa";
import Juntos from "src/components/Home/Juntos";
import Contact from "src/components/Home/Contact";
import ThinkiaTraining from "src/components/Home/ThinkiaTraining";

const Home = () => {
  return (
    <>
      <Menu />
      <Hero />
      <div
        style={{
          width: "100%",
          overflow: "hidden",
          backgroundColor: "#07081C",
        }}
      >
        <img
          src="assets/img/logo/ondas_azul.png"
          alt="Descriptive text"
          className="img-fluid p-o"
          style={{ width: "100%", display: "block" }}
        />
      </div>
      <Services />
      <QuienesSomos />
      <Presentes />
      <QueHacemos />
      <TopAbout />
      <Lupa />
      <Disenyamos />
      <ThinkiaTraining />
      <Futuro />
      <Compania />
      <Juntos />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
