export const homeAndFormTexts = {
    formSection: {
        name: "Nombre *",
        phone: "Teléfono *",
        email: "Correo *",
        message: "Si tienes dudas escríbenos aquí tu consulta ...",
        rgpdText: "Acepto el tratamiento de datos y políticas de privacidad de Thinkia (Search Engine Business S.L.).*",
        informationButton: "Quiero más información"
    }
};
export const sectionTwoTexts = {
    mainSection: {
        title: {
            firstPart: "Aprende de la mano de destacados profesionales del sector, en activo y con contrastada experiencia docente.",
            secondPart: "Adquiere nuevos conocimientos y las habilidades que demandan las empresas."
        },
        stats: [
            {
                number: "10",
                label: "SEMANAS"
            },
            {
                number: "100",
                label: "HORAS"
            },
            {
                number: "8",
                label: "DOCENTES EXPERTOS"
            }
        ]
    },
    learnSection: {
        title: "¿Qué vas a conseguir?",
        benefits: [
            {
                text: "Obtener una visión detallada del ciclo de vida de un producto digital paso a paso."
            },
            {
                text: "Lanzar productos digitales que satisfagan las necesidades reales de los usuarios y se alineen con los objetivos empresariales.",
                specialHeight: true
            },
            {
                text: "Aprender a utilizar métricas y herramientas para la toma de decisiones basadas en datos y evaluar su impacto."
            },
            {
                text: "Conocer perfiles de equipos tecnológicos que facilitarán la negociación con stakeholders y el trabajo en equipos multidisciplinares y por squads.",
                specialHeight: true
            },
            {
                text: "Familiarizarse con los conceptos, roles y prácticas clave del Product Manager en su entorno profesional."
            }
        ]
    }
};

export const accordionTexts = {
    title: "Contenido del Máster",
    modules: [
        {
            number: 'Módulo 01',
            title: 'Kick-Off Product Management',
            content: [
                {
                    title: 'El Rol de Product Manager:',
                    description: 'Introducción al rol de Product Manager, incluyendo claves, habilidades, competencias y responsabilidades, así como la diferencia entre Project Manager y Product Manager.'
                },
                {
                    title: 'El Día a Día de un Product Manager y Presentación del Caso:',
                    description: 'Información sobre equipos multidisciplinarios, perfiles, stakeholders, y reuniones importantes. Presentación del caso a trabajar durante el curso.'
                }
            ]
        },
        {
            number: 'Módulo 02',
            title: 'Estrategia de Producto',
            content: [
                {
                    title: 'El Producto – Creación de Valor:',
                    description: 'Estrategia de producto, identificación de necesidades y oportunidades, y cómo abordarlas. Estudio de conceptos como user persona, Maslows needs y Product Market Fit, aplicados a ejemplos reales.'
                },
                {
                    title: 'El Ciclo del Producto',
                    description: 'Visión general del ciclo del producto, incluyendo fases como discovery, assumptions, validation, soluciones, priorización, desarrollo, métricas y Go to Market. Aprender e iterar.'
                }
            ]
        },
        {
            number: 'Módulo 03',
            title: 'Framing Problems',
            content: [
                {
                    title: 'Discovery & Assumptions:',
                    description: 'Manejo de datos cualitativos y cuantitativos, herramientas y ejemplos reales, y establecimiento de assumptions para validar.'
                },
                {
                    title: 'Validación del Problema:',
                    description: 'Métodos para validar problemas, recogida de datos, y principales métricas.'
                }
            ]
        },
        {
            number: 'Módulo 04',
            title: 'Métricas y Datos',
            content: [
                {
                    title: 'OKRs y KPIs– Seguimiento de Métricas:',
                    description: 'Planificación y monitoreo de OKRs y KPIs, y dominio de métricas de seguimiento e impacto en producto y negocio.'
                },
                {
                    title: 'Data Analyst: Definir, Extraer y Analizar Métricas:',
                    description: 'Conocimiento del rol del Data Analyst, colaboración, evaluación del impacto del producto mediante métricas de éxito.'
                }
            ]
        },
        {
            number: 'Módulo 05',
            title: 'Equipos Multidisciplinares',
            content: [
                {
                    title: 'Product Designer y Design Thinking:',
                    description: 'Comprensión del rol del Product Designer, colaboración, sistema de diseño y herramientas.'
                },
                {
                    title: 'Equipo de Desarrollo:',
                    description: 'Roles, tecnologías, perfiles, estructuras y conceptos clave del equipo de desarrollo'
                },
                {
                    title: 'Metodologías Ágiles:',
                    description: 'Diferentes metodologías ágiles, su aplicación y procedimientos.'
                }
            ]
        },
        {
            number: 'Módulo 06',
            title: 'Soluciones',
            content: [
                {
                    title: 'Validación de Ideas',
                    description: 'Métodos para medir y analizar soluciones, herramientas de test, A/B testing, prototipado, focus groups, research y benchmarking para establecer estándares de éxito.'
                },
                {
                    title: 'Sistemas de Priorización:',
                    description: 'Diferentes sistemas de priorización del backlog de funcionalidades, cuándo usarlos y ejercicios prácticos.'
                }
            ]
        },
        {
            number: 'Módulo 07',
            title: 'Story Mapping',
            content: [
                {
                    title: 'El Rol de Product Manager:',
                    description: 'Introducción al rol de Product Manager, incluyendo claves, habilidades, competencias y responsabilidades, así como la diferencia entre Project Manager y Product Manager.'
                },
                {
                    title: 'El Día a Día de un Product Manager y Presentación del Caso:',
                    description: 'Información sobre equipos multidisciplinares, perfiles, stakeholders, y reuniones importantes. Presentación del caso a trabajar durante el curso.'
                }
            ]
        },
        {
            number: 'Módulo 08',
            title: 'Product Launch',
            content: [
                {
                    title: 'El Rol de Product Manager:',
                    description: 'Introducción al rol de Product Manager, incluyendo claves, habilidades, competencias y responsabilidades, así como la diferencia entre Project Manager y Product Manager.'
                },
                {
                    title: 'El Día a Día de un Product Manager y Presentación del Caso:',
                    description: 'Información sobre equipos multidisciplinares, perfiles, stakeholders, y reuniones importantes. Presentación del caso a trabajar durante el curso.'
                }
            ]
        },
        {
            number: 'Módulo 09',
            title: 'Desarrollo Profesional',
            content: [
                {
                    title: 'Caso Real con una Empresa:',
                    description: 'Experiencia práctica con un Product Trio en una empresa real, resolución de problemas en equipo y vivencia directa del rol de Product Manager.'
                }
            ]
        },
        {
            number: 'Módulo 10',
            title: 'Proyecto Final ',
            content: [
                {
                    description: 'Confirmación de la comprensión y aplicación de los contenidos del Máster mediante un proyecto libre basado en un caso real o hipotético.'
                }
            ]
        }
    ]
};
export const targetAudienceTexts = {
    mainTitle: "¿A quién va dirigido?",
    prerequisites: {
        title: "Pre-requisitos:",
        description: "En esta formación de producto nos centraremos en todo aquello que necesita conocer y controlar un Product Manager en su día a día, desde conceptos teóricos del ciclo de creación del producto hasta cómo desenvolverte en empresas tecnológicas para tener impacto en resultados."
    },
    profiles: {
        title: "Orientado a perfiles como",
        list: [
            {
                title: "Product Managers",
                description: "Profesionales en activo que buscan reforzar sus habilidades, avanzar en su carrera, conectar con otros Product Managers o conocer casos de otras industrias."
            },
            {
                title: "Recién licenciados",
                description: "Personas recién graduadas interesadas en formarse en la profesión de Product Manager, entender el funcionamiento de las empresas tecnológicas y conocer a otros profesionales del sector."
            },
            {
                title: "Otros perfiles",
                description: "Profesionales que actualmente ocupan otros cargos y desean orientar su carrera hacia el ámbito del producto, independientemente de su relación con la tecnología o productos digitales."
            }
        ]
    },
    features: [
        {
            iconAlt: "Icono casa",
            text: "Desde Casa"
        },
        {
            iconAlt: "Icono Másterclass",
            text: "Másterclasses en directo"
        },
        {
            iconAlt: "Icono expertos",
            text: "Profesionales expertos en activo"
        }
    ]
};
export const upcomingSectionTexts = {
    mainTitle: "Próximas Convocatorias",
    edition: {
        items: [
            {
                text: "I Edición"
            },
            {
                text: "4 Abril 2025"
            },
            {
                text: "Masterclass en directo\nViernes y Sábados"
            },
            {
                text: "Vie. 15:00 a 20:00h\nSáb. 9:00 a 14:00h"
            }
        ]
    },
    pricing: {
        label: "Precio",
        amount: "3.400€",
        buttonText: "Quiero más información"
    },
    details: {
        title: "Detalles del Máster",
        list: [
            "10 semanas - 100 horas",
            "8 docentes en activo",
            "Certificación al finalizar",
            "Masterclass en directo"
        ]
    }
};
export const faqSectionTexts = {
    title: "Preguntas Frecuentes",
    questions: [
        {
            question: "¿La formación es en directo?",
            answer: "Sí, hemos ideado nuestro Máster en Product Management como una formación completamente online pero sin perder la esencia de poder vivirla compartiendo la experiencia en directo con tus compañeros y profesores. Así pues, cada sesión es en directo para que puedas preguntar tus dudas a tus profesores y hablar con tus compañeros."
        },
        {
            question: "¿Cómo puedo acceder al Máster?",
            answer: "Es tan fácil como completar la solicitud con tus datos y nos pondremos rápidamente en contacto contigo. Agendaremos una entrevista para conocer tus intereses y ayudarte a alcanzar tu objetivo."
        },
        {
            question: "¿Qué requisitos hacen falta?",
            answer: "Partirás con una base si tienes conocimiento sobre producto, desarrollo, data, marketing o negocio, pero no son requerimientos obligatorios."
        },
        {
            question: "¿Qué perfil debo tener para poder acceder?",
            answer: "No existe un perfil ideal ni un background concreto para llegar a ser un buen/a PM, algunos/as llegan desde el mundo de la programación, marketing o negocio por lo que te será más sencillo si provienes de alguna de estas ramas. Aún así, no es necesario tener nociones previas en estas áreas. Nuestro equipo de Admisiones realizará una entrevista contigo para conocerte mejor y poder asesorarte en tu carrera profesional."
        },
        {
            question: "¿Por qué elegir este Máster y no otro?",
            answer: "En Thinkia Training hemos creado un Máster 100% online con Masterclass en directo cada viernes y sábado en el que adquirás de la mano de profesionales en activo que tienen experiencia con este rol, y estarás en contacto con compañeros en un punto profesional similar al tuyo, y con las mismas inquietudes. Estamos totalmente comprometidos con tu formación, por eso hemos diseñado un programa adaptado a las necesidades actuales de las empresas con profesores que son profesionales de alto nivel en activo."
        },
        {
            question: "¿Lo puedo hacer mientras estoy trabajando?",
            answer: "El horario está adaptado al horario laboral para que puedas compaginar tu trabajo con tu formación. Además, al tratarse de una formación online puedes conectarte desde cualquier lugar a la hora de la Masterclass y pondremos a tu disposición la documentación que aporte cada profesor en nuestra plataforma formativa. Y si algún día no puedes conectarte las sesiones se grabarán para verlas luego. o poder repasar la clase con el experto."
        }
    ]
};

const textContextData = {
    homeAndFormTexts,
    sectionTwoTexts,
    accordionTexts,
    targetAudienceTexts,
    upcomingSectionTexts,
    faqSectionTexts
};

export default textContextData;